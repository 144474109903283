import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ProjectPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  demo,
  source,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      <div className="container content post-container">
        <div className="columns">
          <div className="column is-10 is-offset-1 post-content">
            <h1 className="title">{title}</h1>
            <p>{description}</p>
            <PostContent content={content} />
            {demo !== 'none' || source !== 'none' ? (
              <div style={{ marginTop: `4rem` }}>
                <h2>Links</h2>
                <ul className="taglist">
                  {demo !== 'none' ? (
                    <li>
                      <a href={demo} target="_blank" rel="noopener noreferrer">
                        Demo
                      </a>
                    </li>
                  ) : null}
                  {source !== 'none' ? (
                    <li>
                      <a
                        href={source}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Source
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : null}
            {tags && tags.length ? (
              <div className="margin-top-10">
                <h2>Tags</h2>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

ProjectPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  demo: PropTypes.string,
  source: PropTypes.string,
}

const ProjectPost = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, description, tags, demo, source },
    },
  },
}) => {
  return (
    <Layout title={`${title} | Projects`} description={description}>
      <ProjectPostTemplate
        content={html}
        contentComponent={HTMLContent}
        description={description}
        tags={tags}
        title={title}
        demo={demo}
        source={source}
      />
    </Layout>
  )
}

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ProjectPost

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        demo
        source
      }
    }
  }
`
